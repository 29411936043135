import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Server = require("Everlaw/Server");
import User = require("Everlaw/User");
import {
    Confirmation,
    ConfirmationModalProps,
    DialogSize,
    Memo,
    Paragraph,
    Span,
} from "design-system";
import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import * as React from "react";

/**
 * Pop up a confirmation if the user has an elevated role and is not on the project. A user who is
 * not on the project but does not have an elevated role (e.g., an org admin?) will NOT see the
 * confirmation dialog.
 *
 * Use as a method decorator. Note that this decorator only works for methods so it can't be used
 * for standalone functions, lambdas, or functions stored to a variable. If that becomes
 * prohibitive, we might also add a function wrapping option here.
 *
 * Note that this changes the return value of the function from X to Promise<X>.
 */
export function ElevatedRoleConfirm(action = "making changes") {
    return function (target: any, name: string, descriptor: PropertyDescriptor) {
        const method = descriptor.value; // references the method being decorated
        descriptor.value = function (...args) {
            // ...args are the arguments that are being passed to the original method
            return new Promise<any>((resolve, reject) => {
                if (User.me.hasElevatedRole() && !User.me.isActiveUser() && Server.isLive()) {
                    QueryDialog.create({
                        title: "Elevated role confirmation",
                        submitText: "Proceed",
                        prompt: Dom.div([
                            Dom.p([
                                "You are ",
                                Dom.b(action),
                                " on project ",
                                Dom.b(Project.CURRENT.display()),
                                " as a user not on the project, using your elevated role.",
                            ]),
                            Dom.p("Please confirm that this action is authorized and correct."),
                        ]),
                        onCancel: () => {
                            reject();
                            return true;
                        },
                        onSubmit: () => {
                            // use .apply and this to give the method proper context.
                            resolve(method.apply(this, args));
                            return true;
                        },
                    });
                } else {
                    resolve(method.apply(this, args));
                }
            });
        };
    };
}

interface ElevatedRoleConfirmProps extends Pick<ConfirmationModalProps, "onHide" | "onCancel"> {
    /**
     * The action being initiated. Defaults to "making changes".
     */
    action?: string;
    /**
     * A callback that is called when the complete button (i.e. the primaryButton) is clicked.
     */
    onComplete: Memo<() => void>;
    /**
     * The setter for the {@link visible} prop.
     */
    setVisible: Dispatch<SetStateAction<boolean>>;
    /**
     * Whether to show the elevated role confirmation.
     */
    visible: boolean;
}

/**
 * A React version of ElevatedRoleConfirm.
 * @param action
 * @param onHide
 * @param onComplete
 * @param onCancel
 * @param visible
 * @constructor
 */
export function ElevatedRoleConfirmationDialog({
    action = "making changes",
    onHide,
    onComplete,
    onCancel,
    visible,
    setVisible,
}: ElevatedRoleConfirmProps): ReactNode {
    const isElevated = User.me.hasElevatedRole() && !User.me.isActiveUser() && Server.isLive();

    useEffect(() => {
        if (!isElevated) {
            visible && onComplete();
            setVisible(false);
            return;
        }
    }, [isElevated, onComplete, setVisible, visible]);

    return (
        isElevated && (
            <Confirmation
                onHide={() => {
                    onHide?.();
                    setVisible(false);
                }}
                onCancel={() => {
                    onCancel?.();
                    setVisible(false);
                }}
                onComplete={() => {
                    onComplete?.();
                    setVisible(false);
                }}
                primaryButton={"Proceed"}
                size={DialogSize.SM}
                title={"Elevated role confirmation"}
                visible={visible}
            >
                <Paragraph>
                    You are <Span.Bold>{action}</Span.Bold> on project
                    <Span.Bold>{Project.CURRENT.display()}</Span.Bold> as a user not on the project,
                    using your elevated role.
                </Paragraph>
                <Paragraph>Please confirm that this action is authorized and correct.</Paragraph>
            </Confirmation>
        )
    );
}
