import dojo_on = require("dojo/on");
import dojo_window = require("dojo/window");
import Base = require("Everlaw/Base");
import Bates = require("Everlaw/Bates");
import Arr = require("Everlaw/Core/Arr");
import Is = require("Everlaw/Core/Is");
import Str = require("Everlaw/Core/Str");
import DateUtil = require("Everlaw/DateUtil");
import Document = require("Everlaw/Document"); // Circular dependency - use for types only
import Dom = require("Everlaw/Dom");
import DomText = require("Everlaw/Dom/Text");
import Input = require("Everlaw/Input");
import Perm = require("Everlaw/PermissionStrings");
import Preference = require("Everlaw/Preference");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import UI = require("Everlaw/UI");
import ActionNode = require("Everlaw/UI/ActionNode");
import Button = require("Everlaw/UI/Button");
import Icon = require("Everlaw/UI/Icon");
import PopoverMenu = require("Everlaw/UI/PopoverMenu");
import TextBox = require("Everlaw/UI/TextBox");
import TextEditor = require("Everlaw/UI/TextEditor");
import Tooltip = require("Everlaw/UI/Tooltip");
import Widget = require("Everlaw/UI/Widget");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import XRegExp = require("xregexp");
import { EverColor } from "design-system";
import { Color } from "Everlaw/ColorUtil";
import { ElevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { ReviewRectangles } from "Everlaw/Review/Highlighting";
import { makeFocusable } from "Everlaw/UI/FocusDiv";
import { RedBlackTree } from "Everlaw/Util/RedBlackTree";

// Common base class for Note and NPSNote
export abstract class BaseNote extends Base.SecuredObject {
    override id: number;
    // `user` can be null for Notes (created by ImportTasks), but should be non-null for NPSNotes.
    user: User = null;
    created: number = null;
    updated: number = null;
    text: string = null;
    protected _userId: User.Id = null;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.user) {
            if (Is.number(params.user)) {
                this._userId = params.user;
                this.user = Base.get(User, this._userId);
            } else {
                // Assume this has already been mixed in before.
                this._userId = params.user.id;
                this.user = params.user;
            }
        }
    }
    abstract commit(): Promise<void>;
    abstract remove(): void;
    override compare(other: BaseNote) {
        return this.created - other.created || this.id - other.id;
    }
    username() {
        return this.user ? this.user.display() : User.displayById(this._userId);
    }
    // Fills the given node with this.text (up to the number of optionally-specified charactres),
    // converting bates numbers in the text to document links.
    toNode(node: HTMLElement, chars: number = 0) {
        if (this.text) {
            if (chars <= 0 || chars >= this.text.length) {
                node.innerHTML = this.text;
            } else {
                // If the note is too long, we try to break it at the last space before the
                // character limit and add text saying "more". If we can't, we just put as much text
                // as we can and don't add more.
                let truncText = this.text.substring(0, chars);
                let finalInnerHtml = truncText;
                const matches = truncText.match(/\s+/g);
                if (matches) {
                    let i = matches.length - 1;
                    while (i >= 0) {
                        const lastIndex = truncText.lastIndexOf(matches[i]);
                        truncText = truncText.substring(0, lastIndex);
                        if (truncText.lastIndexOf(">") > truncText.lastIndexOf("<")) {
                            // If the space is within the text section of an element, we break it up
                            // and add text saying "more"
                            finalInnerHtml = truncText + "...&lt;more&gt;";
                            break;
                        } else {
                            // If our space is within the attribute section, we don't want to break
                            // on it. For example, splitting on the spaces in
                            // "<span style="text-decoration">jkljlkj</span>" would give us
                            // misformed html
                            i -= 1;
                        }
                    }
                }
                // Note that when inner html is assigned, it automatically adds closing tags to
                // unclosed elements, otherwise the above code wouldn't work.
                node.innerHTML = finalInnerHtml;
            }
        } else {
            node.innerHTML = "";
        }
    }

    date() {
        return this.updated || this.created;
    }

    hasText() {
        return !!this.text;
    }
}

/*
 * Base class for Notes.
 * All notes (i.e. both Atty notes and Highlight notes)
 * use this class.
 */

export class Note extends BaseNote {
    get className() {
        return "Note";
    }
    override id: NoteUtil.Id;
    parentType: NoteUtil.ParentType;
    parentId: string | number;
    docId: Document.Id;
    linkHandlers: dojo_on.Handle[] = [];
    override _mixin(params: any) {
        super._mixin(params);
    }
    /**
     * Commit changes to this note.
     */
    @ElevatedRoleConfirm("adding or editing a note")
    public commit() {
        return Rest.post("documents/saveNote.rest", {
            // Always has:
            docId: this.docId,
            text: this.text,
            parentId: this.parentId,
            parentType: this.parentType,
            // If new, doesn't have:
            noteId: this.id,
        }).then((data) => {
            // TODO: We add/publish the note here, but saveNote.rest publishes the updated
            // document, and that also contains our note. Document._mixin then calls Base.set
            // with the JSON of our note as well. This doesn't actually cause problems since the
            // document stores these notes by their ID, it just causes lots of extra publish
            // events. Since we use `add`, we ensure that `this` is actually the global note,
            // even in the event of a race condition where we finish after the mux notification.
            // Since the document uses Base.set, it will only update these fields. In either
            // case, anyone with a reference to this continues to be valid.
            //
            // If we want to remove this logic and rely on the mux notification, then we'll have
            // to be sure that none of the callers hold onto this note object. We can't remove
            // the publishing logic from the notification side, because then other users won't
            // learn about our new note.
            if (!Is.number(this.id)) {
                this.id = data.id;
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }
    @ElevatedRoleConfirm("removing a note")
    remove(callback?: (n: Note, msg?: string) => void, error?: Rest.Callback) {
        Util.destroy(this.linkHandlers);
        if (Is.number(this.id)) {
            Rest.post("documents/deleteNote.rest", { noteId: this.id }).then(
                () => {
                    Base.remove(this);
                    callback && callback(this);
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
        } else {
            // This wasn't a saved note - we don't have to do any deleting, but we should call the
            // callback!
            callback && callback(this);
        }
    }
    committed() {
        return Is.number(this.id);
    }

    // A page may set this callback in order to handle timestamp link clicks internally instead of
    // opening a new review page.
    static timestampLinkHandler: (secs: number) => void;
    private insertTimestampLinks(node: HTMLElement) {
        // It may be possible to catpure all the possibilities in one Regex here, but for
        // readability and ease of coding we separate the cases.
        const hoursRegex: RegExp = XRegExp("\\b(\\d?\\d):([0-5]\\d):([0-5]\\d)\\b");
        const noHoursRegex: RegExp = XRegExp("\\b([0-5]?\\d):([0-5]\\d)\\b");
        DomText.walk(node, (textNode, parentNode) => {
            if (parentNode instanceof HTMLAnchorElement) {
                // already linkified
                return;
            }
            const text = textNode.nodeValue;
            let replacement: DocumentFragment;
            let pos = 0;
            while (pos < text.length) {
                const hoursMatch = XRegExp.exec(text, hoursRegex, pos);
                const noHoursMatch = XRegExp.exec(text, noHoursRegex, pos);
                let firstMatch: RegExpExecArray;
                let hours = 0;
                let minutes = 0;
                let seconds = 0;
                if (hoursMatch && (!noHoursMatch || hoursMatch.index <= noHoursMatch.index)) {
                    hours = parseInt(hoursMatch[1]);
                    minutes = parseInt(hoursMatch[2]);
                    seconds = parseInt(hoursMatch[3]);
                    firstMatch = hoursMatch;
                } else if (noHoursMatch) {
                    minutes = parseInt(noHoursMatch[1]);
                    seconds = parseInt(noHoursMatch[2]);
                    firstMatch = noHoursMatch;
                } else {
                    break;
                }
                const start = firstMatch.index;
                const tsString = firstMatch[0];
                if (replacement) {
                    Dom.addContent(replacement, text.substring(pos, start));
                } else {
                    replacement = Dom.fragment(text.substring(0, start));
                }
                const totalSecs = hours * 3600 + minutes * 60 + seconds;
                const anchor = Dom.a(
                    Note.timestampLinkHandler
                        ? {}
                        : {
                              target: "_blank",
                              href: "review.do#doc=" + this.docId + "&tstamp=" + totalSecs,
                          },
                    tsString,
                );
                Dom.addContent(replacement, anchor);
                this.linkHandlers.push(
                    dojo_on(anchor, Input.tap, (evt) => {
                        Note.timestampLinkHandler && Note.timestampLinkHandler(totalSecs);
                        evt.stopPropagation();
                    }),
                );
                pos = start + tsString.length;
            }
            if (replacement) {
                const remainder = text.substring(pos, text.length);
                if (remainder) {
                    Dom.addContent(replacement, remainder);
                }
            } // else we made no changes; we'll return undefined, and the original text node will remain
            return replacement;
        });
    }

    private insertDocLinks(node: HTMLElement) {
        Bates.insertBatesLinks(node, (docId, batesText) => {
            const anchor = Dom.a(
                {
                    target: "_blank",
                    href: "review.do#doc=" + docId,
                },
                batesText,
            );
            this.linkHandlers.push(
                dojo_on(anchor, Input.tap, (evt) => {
                    evt.stopPropagation();
                }),
            );
            return anchor;
        });
        if (this.docId) {
            const doc: Document = Base.get("Document", this.docId);
            if (doc && (doc.type === "Audio" || doc.type == "Video")) {
                this.insertTimestampLinks(node);
            }
        }
    }

    override toNode(node: HTMLElement, chars: number = 0) {
        super.toNode(node, chars);
        if (this.text) {
            Util.destroy(this.linkHandlers);
            this.insertDocLinks(node);
        }
    }
}

// TODO this is a bandaid on our old style of combining module and class import with a single name.
// There are a ton of things in here that belong as static members of the Note class, and a bunch
// that belong as explicit exports from the root of this file.
export module NoteUtil {
    export type Id = number & Base.Id<"Note">;

    export enum ParentType {
        Document = "Document",
        Highlight = "Highlight",
        SpreadsheetRedaction = "SpreadsheetRedaction",
        FsiSpreadsheetRedaction = "FsiSpreadsheetRedaction",
        ImageRedaction = "ImageRedaction",
        MetadataRedaction = "MetadataRedaction",
        FpiRedaction = "FpiRedaction",
        MediaRedaction = "MediaRedaction",
    }

    export const defaultColor = Color.fromEverColor(EverColor.YELLOW_30);

    var editorId = 0;

    interface PanelEntry<N extends BaseNote> {
        node: HTMLElement;
        expandHandle: dojo_on.Handle;
        editor?: TextEditor;
        note: N;
        contents: HTMLElement;
        date: HTMLElement;
        actionsRow: HTMLElement;
        templateMenu: PopoverMenu;
    }

    class NotePanelEntries<N extends BaseNote> extends RedBlackTree<PanelEntry<N>> {
        constructor(protected noteComparator: (a: N, b: N) => number) {
            super((a: PanelEntry<N>, b: PanelEntry<N>) => {
                return this.noteComparator(a.note, b.note);
            });
        }

        getPreviousEntry(n: N): PanelEntry<N> {
            let node = this.getNodeFromNote(n);
            if (!node) {
                return null;
            }
            if (node.left) {
                return this.getRightmostDescendant(node.left).value;
            } else {
                while (node.parent) {
                    if (node.isRightChild()) {
                        return node.parent.value;
                    }
                    node = node.parent;
                }
                return null;
            }
        }

        containsNote(n: N) {
            return this._contains((e) => this.noteComparator(n, e.note), this.root);
        }

        protected getNodeFromNote(n: N) {
            return this.getNode((entry) => this.noteComparator(n, entry.note), this.root);
        }

        entryFromNote(n: N): PanelEntry<N> {
            return this.getNodeFromNote(n).value;
        }
    }

    /**
     * A note panel for displaying a list of text notes.
     * This panel has no notion of visual state (i.e. is it open or closed) - it's up to the user to
     * manage this.
     */
    export abstract class BasePanel<N extends BaseNote> {
        // Display template menus
        includeTemplates = false;
        // Include the toolbar (should probably be true if includeTemplates is true)
        includeToolbar = true;
        // Can be passed during construction, updated locally after that.
        lastNoteText: string = null;
        // If provided, an "Insert" button will appear in the action row instead of edit/delete icons
        onInsert: (text: string) => void;
        // If false, the entire note contents and the action row will always be shown
        collapsible = true;
        // Additional toolbar items can be placed in this div
        toolbarAddOns: HTMLElement;
        footer: HTMLDivElement;
        // Category (first parameter) used for GA events
        getGACategory() {
            return "Notes";
        }
        // Label (third parameter) used for GA events
        getGALabel: () => string = function () {
            return "";
        };
        // Show text only without name and date
        showTextOnly: boolean = false;
        _node: HTMLElement;
        protected isEditing = false;
        private toolbar: HTMLElement;
        private templates: string[] = null;
        private saveTemplateMenuItems: PopoverMenu.Item[] = [];
        private newNoteMenu: PopoverMenu;
        private addButton: HTMLElement;
        protected noteNode: HTMLElement;
        private empty: HTMLElement;
        private entries: NotePanelEntries<N>;
        private lastExpanded: PanelEntry<N> = null;
        protected editable = true; // Whether the notes in this panel can (potentially) be edited
        protected toDestroy: Util.Destroyable[] = [];
        private newNote: PanelEntry<N>;
        protected iconTray: HTMLElement;
        private static MIN_TEMPLATES = 3;
        constructor(params?: any) {
            Object.assign(this, params);
            this._node = Dom.div({ class: "notes-panel" });
            this.resetEntries();
            if (this.includeTemplates) {
                this.templates = Preference.REVIEW.templates.get();
                // Create save template items for the base slots and any more initial templates
                const max = Math.max(this.templates.length, BasePanel.MIN_TEMPLATES);
                for (let i = 0; i < max; i++) {
                    this.saveTemplateMenuItems.push(this.getSaveTemplateMenuItem(i));
                }
                // If the base slots are full (or at least the third one is), add an additional slot.
                if (max === this.templates.length) {
                    this.saveTemplateMenuItems.push(this.getSaveTemplateMenuItem(max));
                }
            }
            this.includeToolbar && this.makeToolbar();
            this.empty = Dom.create(
                "div",
                {
                    class:
                        "notes-panel-empty "
                        + (this.includeToolbar && this.userCanCreate() ? "h-spaced-16" : ""),
                    content: NoteUtil.canReadNotes()
                        ? "No notes yet"
                        : "You do not have permissions to view notes.",
                },
                this._node,
            );
            this.noteNode = Dom.create("div", this._node);
            this.footer = Dom.div({ class: "notes-panel__footer" });
            this.iconTray = Dom.div({ class: "icon-tray" });
            Dom.place(this.footer, this._node);
            Dom.place(this.iconTray, this.footer);
        }
        public addRemover(onRemoval: () => void, tooltip: string, isDisabled = false) {
            const trashIcon = new Button.IconButton({
                iconClass: "trash",
                onClick: () => {
                    this.resetEntries();
                    onRemoval();
                },
                parent: this.iconTray,
            });
            UI.toggleDisabled(trashIcon, isDisabled);
            this.toDestroy.push(trashIcon);
        }
        public resetEntries() {
            this.entries
                && this.entries.forEach((entry) => {
                    this.destroyEntry(entry);
                });
            this.entries = new NotePanelEntries<N>((a: N, b: N) => {
                if (!!a.id && !!b.id && a.id === b.id) {
                    return 0;
                }
                if (!a.created && !!b.created) {
                    return -1;
                } else if (!b.created && !!a.created) {
                    return 1;
                }
                // sort a, b first by time created and then by id (no id is treated as high value id)
                return a.created < b.created
                    ? 1
                    : a.created > b.created
                      ? -1
                      : a.id && (!b.id || a.id > b.id)
                        ? -1
                        : b.id && (!a.id || b.id > a.id)
                          ? 1
                          : 0;
            });
        }
        public addSearcher(tooltip: string[], isDisabled: boolean, onSearch: () => void) {
            const searchIcon = new Button.IconButton({
                iconClass: "search",
                tooltip: tooltip,
                onClick: () => {
                    onSearch();
                },
                parent: this.iconTray,
            });
            searchIcon.setDisabled(isDisabled);
            this.toDestroy.push(searchIcon);
        }
        private notInFlight(): boolean {
            return !!this.entries;
        }
        private makeToolbar() {
            this.toolbar = Dom.create("div", { class: "notes-panel-toolbar" }, this._node);
            if (this.userCanCreate()) {
                if (this.templates) {
                    let menuItems: PopoverMenu.Item[] = [
                        {
                            id: "Placeholder",
                            label: "No saved templates",
                            onClick: () => {},
                        },
                        {
                            id: "Last",
                            label: this.getNewNoteFromLastMenuLabel(),
                        },
                    ];
                    // We create menu items for MIN_TEMPLATES even if they don't exist yet, as it is
                    // easier to hide them initially and show them later than it is to insert new items
                    // in the middle of the menu.
                    const max = Math.max(this.templates.length, BasePanel.MIN_TEMPLATES);
                    for (let i = 0; i < max; i++) {
                        menuItems.push(this.getNewNoteFromTemplateMenuItem(i));
                    }
                    const opener = this.makeTemplateMenuOpener(this.toolbar);
                    this.newNoteMenu = new PopoverMenu(
                        {
                            menuItems: menuItems,
                            onClick: (item) => {
                                this.writeNewNote(
                                    item.id === "Last"
                                        ? this.lastNoteText
                                        : this.templates[+item.id],
                                );
                                ga_event(
                                    this.getGACategory(),
                                    item.id === "Last" ? "Apply Template" : "Apply Last Note",
                                    this.getGALabel(),
                                );
                            },
                        },
                        opener,
                    );
                    this.toDestroy.push(this.newNoteMenu);
                    // Hide irrelevant items
                    let empty = true;
                    for (let i = 0; i < BasePanel.MIN_TEMPLATES; i++) {
                        if (this.templates[i]) {
                            empty = false;
                        } else {
                            this.newNoteMenu.showItem(String(i), false);
                        }
                    }
                    this.newNoteMenu.showItem("Last", !!this.lastNoteText);
                    this.newNoteMenu.showItem("Placeholder", !this.lastNoteText && empty);
                }
                this.addButton = Dom.create(
                    "div",
                    {
                        class: "notes-add-button action",
                        content: "Add note",
                    },
                    this.toolbar,
                );
                Dom.setAriaLabel(this.addButton, "Add note");
                if (
                    User.me.can(
                        Perm.CREATE_NOTES,
                        Project.CURRENT,
                        User.Override.ELEVATED_OR_ORGADMIN,
                    )
                ) {
                    this.toDestroy.push(
                        dojo_on(this.addButton, Input.tap, () => {
                            this.writeNewNote(); // Not bound directly to avoid passing in extra arguments.
                        }),
                    );
                } else {
                    this.toDestroy.push(
                        new Tooltip(this.addButton, "You do not have permission to create a note."),
                    );
                }
            }
            this.toolbarAddOns = Dom.create("div", { class: "notes-toolbar-addons" }, this.toolbar);
        }
        /*
         * Methods for creating tooltip menu items
         */
        private getSaveTemplateMenuItem(idx: number) {
            return {
                id: String(idx),
                label: this.getSaveTemplateMenuLabel(idx),
            };
        }
        private getSaveTemplateMenuLabel(idx: number): string {
            return (
                "Save as template "
                + this.getTemplateName(idx)
                + ": "
                + this.getMenuItemNoteDescription(this.templates[idx])
            );
        }
        private getNewNoteFromTemplateMenuItem(idx: number) {
            return {
                id: String(idx),
                label: this.getNewNoteFromTemplateMenuLabel(idx),
            };
        }
        private getNewNoteFromTemplateMenuLabel(idx: number): string {
            return (
                "New note from template "
                + this.getTemplateName(idx)
                + ": "
                + this.getMenuItemNoteDescription(this.templates[idx])
            );
        }
        private getNewNoteFromLastMenuLabel(): string {
            return "New note from last note: " + this.getMenuItemNoteDescription(this.lastNoteText);
        }
        private getTemplateName(idx: number): string {
            // A-Z for the first 26, then numbers beyond that.
            return idx < 26 ? String.fromCharCode("A".charCodeAt(0) + idx) : String(idx + 1);
        }
        private getMenuItemNoteDescription(text: string): string {
            return text ? Str.quoted(Str.ellipsify(DomText.htmlToText(text), 20)) : "";
        }

        /**
         * Returns a new BaseNote (N) entity. Used in the workflow when the user adds a new note.
         * Its text field will be populated before being saved.
         */
        protected abstract createNote(): N;
        protected filter(note: N) {
            return true;
        }
        protected userCanCreate() {
            return true;
        }
        protected userCanEdit(note: N) {
            return this.editable;
        }
        // Called whenever a note is added, edited or deleted
        onContentChange() {}
        // Callback for when a note gets saved from this panel. Generally you should use Base.subscribe
        // but sometimes you need to know that the note came from here.
        onSaveNote(note: N) {}
        onNotePublish(note: N | N[], removed: boolean) {
            let changed = false;
            Arr.wrap(note).forEach((note) => {
                if (this.filter(note)) {
                    changed = true;
                    if (removed) {
                        this.delete(note);
                    } else {
                        this.add(note);
                    }
                }
            });
            if (changed && this.notInFlight()) {
                Dom.show(this.empty, this.numNotes() === 0);
                this.onContentChange();
            }
        }
        blurOpenEditors() {
            this.newNote && this.newNote.editor && this.newNote.editor.blur();
            this.entries.forEach((entry) => {
                if (entry.editor) {
                    entry.editor.blur();
                }
            });
        }
        // Start editing a new note with the given initial text.
        writeNewNote(initialText?: string) {
            if (this.userCanCreate()) {
                const newNote = this.createNote();
                newNote.text = initialText;
                const processed = this.add(newNote);
                Dom.hide(this.empty);
                this.startEdit(processed);
                dojo_window.scrollIntoView(processed.node);
                this.toolbar && Dom.hide(this.toolbar);
                this.newNote = processed;
            }
        }
        private add(note: N) {
            if (!this.notInFlight()) {
                return null;
            }
            let entry: PanelEntry<N>;
            if (Is.number(note.id) && this.entries.containsNote(note)) {
                // Already exists - update the text and date.
                entry = this.entries.entryFromNote(note);
                note.toNode(
                    entry.contents,
                    this.collapsible
                        && (!this.lastExpanded || this.lastExpanded.note.id !== note.id)
                        ? 80
                        : 0,
                );
                Dom.setContent(entry.date, ", " + DateUtil.displayShortDateLocal(note.date()));
            } else {
                // New.
                entry = this.buildNoteEntry(note);
                if (Is.number(note.id)) {
                    this.entries.add(entry);
                }
                const previousEntry = this.entries.getPreviousEntry(note);
                if (previousEntry) {
                    Dom.place(entry, previousEntry, "after");
                } else {
                    Dom.place(entry, this.noteNode, "first");
                }
            }
            return entry;
        }
        notes() {
            return this.entries.toArray().map((e) => e.note);
        }
        private numNotes() {
            return this.entries.size();
        }
        private createEditor(entry: PanelEntry<N>) {
            const callback = this.onContentChange.bind(this);
            const onBlur = (text, editor, cancel) => {
                this.isEditing = false;
                editor.hide();
                Dom.removeClass(entry.node, "editing");
                if (cancel || !text) {
                    // No text, or we're cancelling.  If the note was already saved, revert the
                    // editor content. Otherwise, it's a new note that nothing was added to, so
                    // we just don't save it.
                    if (Is.number(entry.note.id)) {
                        editor.setValue(entry.note.text);
                        entry.note.toNode(entry.contents);
                    }
                    ga_event(this.getGACategory(), "Cancel Edit", this.getGALabel());
                } else if (text !== entry.note.text || !Is.number(entry.note.id)) {
                    // New text, or a new note.
                    entry.note.text = text;
                    entry.note.commit();
                    this.lastNoteText = entry.note.text;
                    if (this.newNoteMenu) {
                        this.newNoteMenu.setItemLabel("Last", this.getNewNoteFromLastMenuLabel());
                        this.newNoteMenu.showItem("Last", true);
                        this.newNoteMenu.showItem("Placeholder", false);
                    }
                    this.onSaveNote(entry.note);
                    ga_event(this.getGACategory(), "Complete Edit", this.getGALabel());
                }
                if (this.newNote) {
                    // For the sake of display before the timeout below gets executed,
                    // hide any new note we've got displayed and show the empty block now.
                    Dom.hide(this.newNote);
                    Dom.show(this.empty, this.numNotes() === 0);
                }
                // We can't delete an editor in its own onblur callback, so set a timeout
                // instead. This cleans up the new note editor, in case that was what we were
                // just using - it's already been hidden above.
                setTimeout(() => {
                    if (this.newNote) {
                        this.destroyEntry(this.newNote);
                    }
                    this.newNote = null;
                }, 0);
                this.toolbar && Dom.show(this.toolbar);
                cancel = false;
            };
            entry.editor = new TextEditor({
                width: "auto",
                divId: entry.contents.id,
                borders: false,
                maxHeight: 300,
                onBlur: function () {
                    onBlur(entry.editor.getValue(), entry.editor, false);
                    callback();
                },
                onCancel: function () {
                    onBlur(entry.editor.getValue(), entry.editor, true);
                    callback();
                },
                onNodeChange: callback,
                onFocus: callback,
            });
            this.toDestroy.push(entry.editor);
        }
        private buildNoteEntry(note: N): PanelEntry<N> {
            const div = Dom.div({ class: "note-entry" });
            const focusDiv = makeFocusable(div, "focus-with-space-style");
            const ts = note.date();
            const date = Dom.span(ts ? ", " + DateUtil.displayShortDateLocal(ts) : "");
            if (!this.showTextOnly) {
                Dom.create(
                    "div",
                    {
                        class: "note-header ellipsized",
                        content: [note.username(), date],
                    },
                    div,
                );
            }
            const contents = Dom.create(
                "div",
                {
                    class: "note-content" + (this.collapsible ? " collapsed" : ""),
                    id: "note-content-" + editorId,
                },
                div,
            );
            Dom.setAriaLabel(div, "Add note here");
            editorId += 1;
            note.toNode(contents, this.collapsible ? 80 : 0);
            let ret: PanelEntry<N>;
            let handle: dojo_on.Handle;
            let actionsRow: HTMLElement;
            let templateMenu: PopoverMenu;
            if (this.notInFlight() && (this.onInsert || this.userCanEdit(note))) {
                actionsRow = Dom.create(
                    "div",
                    {
                        class: "note-actions" + (this.collapsible ? " hidden" : ""),
                    },
                    div,
                );
                const leftActions = Dom.create(
                    "span",
                    {
                        class: "left",
                    },
                    actionsRow,
                );
                const rightActions = Dom.create(
                    "span",
                    {
                        class: "right",
                        // Align edit and delete icons with insert button
                        style: this.onInsert ? { marginTop: "7px" } : null,
                    },
                    actionsRow,
                );
                if (this.onInsert) {
                    new Button({
                        label: "Insert",
                        onClick: () => {
                            this.onInsert(note.text);
                        },
                        class: "skinny rounded safe",
                        parent: rightActions,
                    });
                } else if (this.userCanEdit(note)) {
                    const editIcon = new Button.IconButton({
                        iconClass: "pencil",
                        tooltip: "Edit this note",
                        onClick: () => {
                            this.startEdit(ret);
                        },
                        parent: rightActions,
                        suppressDojoEvent: "tap",
                    });
                    Dom.style(editIcon, { marginRight: "8px" });
                    const deleteIcon = new Button.IconButton({
                        iconClass: "trash",
                        tooltip: "Delete this note",
                        onClick: () => note.remove(),
                        parent: rightActions,
                        suppressDojoEvent: "tap",
                    });
                    this.toDestroy = this.toDestroy.concat([editIcon, deleteIcon]);
                    if (this.templates) {
                        const opener = this.makeTemplateMenuOpener(leftActions);
                        templateMenu = new PopoverMenu(
                            {
                                menuItems: this.saveTemplateMenuItems,
                                onClick: (item) => {
                                    this.saveTemplate(note, item.id);
                                },
                                makeFocusable: true,
                            },
                            opener,
                        );
                    }
                }
            }
            if (this.collapsible) {
                const collapsibleFunc = () => {
                    if (this.lastExpanded) {
                        Dom.addClass(this.lastExpanded.contents, "collapsed");
                        this.lastExpanded.actionsRow && Dom.hide(this.lastExpanded.actionsRow);
                        this.lastExpanded.note.toNode(this.lastExpanded.contents, 80);
                    }
                    if (this.lastExpanded === ret) {
                        this.lastExpanded = null;
                    } else {
                        Dom.removeClass(contents, "collapsed");
                        actionsRow && Dom.show(actionsRow);
                        note.toNode(contents);
                        this.lastExpanded = ret;
                    }
                };
                handle = dojo_on(div, Input.tap, (e) => collapsibleFunc());
                focusDiv.registerDestroyable(
                    Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => collapsibleFunc()),
                );
            }
            ret = {
                node: div,
                expandHandle: handle,
                note: note,
                contents: contents,
                date: date,
                actionsRow: actionsRow,
                templateMenu: templateMenu,
            };
            return ret;
        }
        private makeTemplateMenuOpener(parent: HTMLElement): HTMLElement {
            return Dom.create(
                "span",
                {
                    class: "menu-opener",
                    content: [
                        Dom.span(
                            {
                                class: "highlight-swatch",
                                style: {
                                    borderColor: EverColor.PARCHMENT_30,
                                    color: EverColor.PARCHMENT_60,
                                },
                            },
                            "T",
                        ),
                        new Icon("caret-down-20").node,
                    ],
                },
                parent,
            );
        }
        private startEdit(entry: PanelEntry<N>) {
            Dom.addClass(entry.node, "editing");
            // Reset the contents of the note's node to the actual note content, removing the links
            // we've inserted.
            entry.contents.innerHTML = entry.note.text || "";
            // Due to a strange issue (probably related to iframes) where the body of the TinyMCE editor
            // gets blown away when we move the panel in the review window during a full-screen mode
            // toggle, we create a new editor every time we edit the note. This is a bit of overkill
            // since we technically don't need a new editor if full-screen mode hasn't toggled since
            // the last editor was created, but it keeps things simple.
            Util.destroy(entry.editor);
            this.createEditor(entry);
            entry.editor.edit(entry.note.text);
            ga_event(this.getGACategory(), "Edit", this.getGALabel());
            this.isEditing = true;
        }
        private delete(note: N) {
            if (this.notInFlight() && this.entries.containsNote(note)) {
                const entry = this.entries.entryFromNote(note);
                this.entries.remove(entry);
                this.destroyEntry(entry);
            }
        }
        private saveTemplate(note: N, id: string) {
            const idx = +id;
            this.templates[idx] = note.text;
            // Update our save menu item label
            const newLabel = this.getSaveTemplateMenuLabel(idx);
            this.saveTemplateMenuItems[idx].label = newLabel;
            // Are we filling up the last template slot? If so, we want to add a new blank one.
            const isMax = idx === this.saveTemplateMenuItems.length - 1;
            if (isMax) {
                this.saveTemplateMenuItems.push(this.getSaveTemplateMenuItem(idx + 1));
            }
            this.entries.forEach((e) => {
                if (e.templateMenu) {
                    e.templateMenu.setItemLabel(id, newLabel);
                    if (isMax) {
                        // Add the new menu item.
                        e.templateMenu.add(this.saveTemplateMenuItems[idx + 1]);
                    }
                }
            });
            // Update our new note menu item label and make sure item visibility is correct
            if (Is.defined(this.newNoteMenu.getItem(id))) {
                this.newNoteMenu.setItemLabel(id, this.getNewNoteFromTemplateMenuLabel(idx));
            } else {
                this.newNoteMenu.add({ id, label: this.getNewNoteFromTemplateMenuLabel(idx) });
            }
            this.newNoteMenu.showItem(id, true);
            this.newNoteMenu.showItem("Placeholder", false);
            // if this template is the same as the last note, hide the last note entry.
            if (this.lastNoteText === note.text) {
                this.newNoteMenu.showItem("Last", false);
            }
            Preference.REVIEW.templates.setUserValue(this.templates);
            ga_event(this.getGACategory(), "Save as Template", this.getGALabel());
        }
        destroy() {
            Util.destroy(this.toDestroy);
            this.toDestroy = [];
            this.entries.forEach(this.destroyEntry);
            if (this.newNote) {
                this.destroyEntry(this.newNote);
            }
            this.newNote = null;
            this.entries = null;
        }
        private destroyEntry(e: PanelEntry<N>) {
            Util.destroy(e.expandHandle);
            Util.destroy(e.editor);
            Util.destroy(e.templateMenu);
            Dom.destroy(e.node);
        }
    }

    export class DocumentNotePanelDelegate {
        private docId: number;
        private parentType: NoteUtil.ParentType;
        private callbackMap = {};
        private unsubscribe: () => void;

        constructor(params) {
            this.docId = params.docid;
            this.parentType = params.parentType;
            this.unsubscribe = Base.subscribe(Note, (notes, isDeleting) => {
                const notesByParentid = this.groupUpdatesByParentId(notes);
                for (const key in notesByParentid) {
                    if (key in this.callbackMap) {
                        this.callbackMap[key](notesByParentid[key], isDeleting);
                    }
                }
            }).unsubscribe;
        }

        private groupUpdatesByParentId(notes: Note[]) {
            let notesByParentId = {};
            for (const note of notes) {
                if (note.parentType === this.parentType) {
                    if (note.parentId in notesByParentId) {
                        notesByParentId[note.parentId].push(note);
                    } else {
                        notesByParentId[note.parentId] = [note];
                    }
                }
            }
            return notesByParentId;
        }

        public register(parentId: any, callback: (notes: Note[], isDeleting: boolean) => void) {
            this.callbackMap[parentId] = callback;
            const existingNotesByParentId = this.groupUpdatesByParentId(Base.get(Note));
            if (parentId in existingNotesByParentId) {
                callback(existingNotesByParentId[parentId], false);
            }
        }

        public destroy() {
            this.unsubscribe();
        }
    }

    export class Panel extends BasePanel<Note> {
        docId: Document.Id;
        // If not provided, this panel shows attorney notes. Otherwise, the panel should display notes
        // attatched to this parent of this ReviewRectangles object.
        rectangles: ReviewRectangles;
        // If true, this panel displays text notes from all Notes after the attorney notes section.
        showAllNoteText: boolean;
        constructor(params: any) {
            super(params);
        }
        protected createNote(): Note {
            return new Note({
                docId: this.docId,
                user: User.me.id,
                parentType: this.rectangles
                    ? this.rectangles.parent.getType()
                    : ParentType.Document,
                parentId: this.rectangles ? this.rectangles.parent.getId() : this.docId,
            });
        }
        protected override filter(note: Note) {
            if (!note.hasText() || note.docId !== this.docId) {
                return false;
            }
            return (
                this.showAllNoteText
                || note.parentType === ParentType.Document
                || (this.rectangles
                    && note.parentType === this.rectangles.parent.getType()
                    && note.parentId === this.rectangles.parent.getId())
            );
        }
        protected override userCanCreate() {
            return NoteUtil.canCreateNotes();
        }
        protected override userCanEdit(note: Note) {
            return this.editable && (!note.committed() || User.me.can(Perm.WRITE, note));
        }
    }

    export function canReadNotes() {
        return User.me.can(Perm.READ_NOTES, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
    }

    export function canCreateNotes() {
        return User.me.can(Perm.CREATE_NOTES, Project.CURRENT, User.Override.ELEVATED);
    }

    export class SingleNoteCreator extends Widget {
        private noteEditor: TextEditor;
        private noteValue: string = "";
        private templates: string[];
        private templateDropdown: PopoverMenu;
        constructor(private onEditorVisibilityChange?: (visible: boolean) => void) {
            super();
            this.node = Dom.create("div", { class: "note-wrapper-configure-redact" });
            let notePreview = Dom.div({ class: "note-preview-configure-redact hidden" });
            let notePreviewHtmlWrapper = Dom.create(
                "div",
                { class: "note-preview-html-wrapper-configure-redact" },
                notePreview,
            );
            let noteTextBoxButton = new TextBox({
                placeholder: "Add note",
                textBoxLabelContent: "Note",
                textBoxLabelPosition: "above",
            });
            let showNoteEditor = () => {
                Dom.hide([noteTextBoxButton, notePreview]);
                this.onEditorVisibilityChange && this.onEditorVisibilityChange(true);
                this.noteEditor.show();
                this.noteEditor.setValue(this.noteValue);
            };
            let deleteNote = () => {
                Dom.hide(notePreview);
                Dom.show(noteTextBoxButton);
                this.noteValue = "";
            };
            let notePreviewButtonWrapper = Dom.create(
                "div",
                {
                    class: "note-preview-button-wrapper-configure-redact",
                },
                notePreview,
            );
            const editIcon = new Button.IconButton({
                iconClass: "pencil",
                tooltip: "Edit note",
                onClick: () => showNoteEditor(),
                parent: notePreviewButtonWrapper,
            });
            Dom.style(editIcon, { marginRight: "8px" });
            const deleteIcon = new Button.IconButton({
                iconClass: "trash",
                tooltip: "Delete note",
                onClick: () => deleteNote(),
                parent: notePreviewButtonWrapper,
            });
            const textBoxClick = new ActionNode(noteTextBoxButton, {
                onClick: (evt) => showNoteEditor(),
            });
            this.registerDestroyable([textBoxClick, editIcon, deleteIcon]);
            let showPreviewOrButton = (isCancel: boolean) => {
                if (isCancel) {
                    this.noteEditor.setValue(this.noteValue);
                } else {
                    this.noteValue = this.noteEditor.getValue();
                    notePreviewHtmlWrapper.innerHTML = this.noteValue;
                }
                if (this.noteValue != "") {
                    Dom.show(notePreview);
                } else {
                    Dom.show(noteTextBoxButton);
                }
                this.noteEditor.hide();
                this.onEditorVisibilityChange && this.onEditorVisibilityChange(false);
            };
            // notesDiv is used as an anchor for the tinymce editor and holds no content of its own
            let notesDiv = Dom.div({ id: "batch-redaction-note-editor", class: "hidden" });
            let notesContainer = Dom.div({ class: "notes-container" }, notesDiv);
            this.templates = Preference.REVIEW.templates.get();
            let customButton: TextEditor.CustomButtonParams = null;
            if (this.templates.length > 0) {
                customButton = {
                    name: "template",
                    onClick: () => {
                        this.templateDropdown && this.templateDropdown.open();
                    },
                    tooltip: "Load Template",
                };
            }
            this.noteEditor = new TextEditor({
                borders: true,
                divId: "batch-redaction-note-editor",
                showOnInit: false,
                focusOnInit: false,
                maxHeight: 200,
                onCancel: () => showPreviewOrButton(true),
                onBlur: () => showPreviewOrButton(false),
                customButton,
                onPostCustomButtonAdded: () => this.addTemplateButton(),
            });
            Dom.place([notesContainer, notePreview, noteTextBoxButton], this.node);
            this.registerDestroyable(this.noteEditor);
        }

        private addTemplateButton() {
            const menuItems: PopoverMenu.Item[] = [];
            for (let i = 0; i < this.templates.length; i++) {
                menuItems.push(this.getNewNoteFromTemplateMenuItem(i));
            }
            // Suboptimal solution, but necessary since tinymce no longer exposes button html or allows
            // the user to choose ids or classes.
            const buttonElems: NodeListOf<HTMLButtonElement> = this.noteEditor.editor
                .getContainer()
                .querySelectorAll("button");
            const templateButtonElem = buttonElems[buttonElems.length - 1];
            this.registerDestroyable(
                new PopoverMenu(
                    {
                        menuItems,
                        onClick: (item) => {
                            this.noteEditor.setValue(this.templates[parseInt(item.id)]);
                        },
                    },
                    templateButtonElem,
                ),
            ); // anchors the template dropdown to the tinymce button
        }

        getNoteValue() {
            return this.noteValue;
        }

        private getNewNoteFromTemplateMenuItem(idx: number) {
            return {
                id: String(idx),
                label: this.getNewNoteFromTemplateMenuLabel(idx),
            };
        }
        private getNewNoteFromTemplateMenuLabel(idx: number): string {
            return (
                "New note from template "
                + this.getTemplateName(idx)
                + ": "
                + this.getMenuItemNoteDescription(this.templates[idx])
            );
        }
        private getTemplateName(idx: number): string {
            // A-Z for the first 26, then numbers beyond that.
            return idx < 26 ? String.fromCharCode("A".charCodeAt(0) + idx) : String(idx + 1);
        }
        private getMenuItemNoteDescription(text: string): string {
            return text ? Str.quoted(Str.ellipsify(DomText.htmlToText(text), 20)) : "";
        }
    }
}
